import React from "react";
import '../App.css';

function MarketingPage() {
    return (<>
    <h1 className="pageTitle">Marketing</h1>
    <p className="pageBody">Keep your design assets safe with Pickalator. Our app allows you to create and save multiple asset types. All of this is provided free of charge.</p>
    <p className="pageBody">Create any of the following assets:</p>
    <div className="pageBody">
    <ul>
        <li>Colors</li>
        <li>Palettes</li>
        <li>Linear Gradients</li>
        <li>Angular Gradients</li>
        <li>Radial Gradients</li>
        <li>Haptic Patterns</li>
    </ul>
    </div>
        </>)
}

export default MarketingPage;