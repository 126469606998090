import React from "react";
import { NavLink } from 'react-router-dom'; 
import logo from '../app-icon.png';

function Nav() {
    return <nav>
            <NavLink to="/">
            <img className="homeLink" src={logo} alt="App icon" />
            </NavLink>

            <NavLink activeClassName="active" to="marketing">
                <h2>Marketing</h2>
            </NavLink>

            <NavLink activeClassName="active" to="privacy">
                <h2>Privacy</h2>
            </NavLink>

            <NavLink activeClassName="active" to="support">
                <h2>Support</h2>
            </NavLink>
    </nav>
}

export default Nav;