import firebase from 'firebase/compat/app'; //v9
import 'firebase/compat/auth'; //v9
import 'firebase/compat/firestore'; //v9

firebase.initializeApp({
    apiKey: "AIzaSyCUHtudCKgaMm3xtA0cW4MuGRHX0zEccRA",
    authDomain: "scheminator.firebaseapp.com",
    projectId: "scheminator",
    storageBucket: "scheminator.appspot.com",
    messagingSenderId: "211849385889",
    appId: "1:211849385889:web:6f8384c400fad02182dde0",
    measurementId: "G-H9QW6M3SM7"
  })

  export default firebase;