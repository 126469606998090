import React from "react";
import '../App.css';

function PrivacyPolicy() {
    return (
    <div>
        <h1 className="pageTitle">Privacy Policy</h1>

        <p className="pageBody">Pickalator requires the user to link an Apple or Google account. This allows you to have control of your created assets in the application. Personal identification information is associated with a randomized user ID and is only accessible to the account owner.</p>
    </div>
    );
}

export default PrivacyPolicy;