import React from "react";
import '../App.css';

function SupportPage() {
    return (<>
    <h1 className="pageTitle">Support</h1>
    <p className="pageBody">If you are having difficulties using Pickalator please reach out. We have a few mechanisms for getting help or providing feedback.</p>
    <p className="pageBody">These methods include: </p>
    <div className="pageBody">
    <ul >
        <li >Emailing our team using this link</li>
        <li >The in app support chat.</li>
        <li >The in app bug reporting tool.</li>
        <li >The in app feature request tool.</li>
    </ul>
    </div>
        </>)
}

export default SupportPage;