import './App.css';
import React from 'react'
import firebase from './firebase.js'; //v9
import Nav from './Components/Nav.js';
import PrivacyPolicy from './Components/PrivacyPolicy';
import MarketingPage from './Components/MarketingPage';
import SupportPage from './Components/SupportPage';
import Home from './Home';

import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

// import react hooks for easy listening 
import { useAuthState } from 'react-firebase-hooks/auth';


const auth = firebase.auth();

function App() {
  const [user] = useAuthState(auth);

  return (
    <Router>
    <div className="App">
      <header>
      <Nav />
      </header>
      <Root />
    </div>
    </Router>
  );
}

function Root() {
  return (
    <>
     <section>
     <Routes>
        <Route path="/" element={ <Home />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route path="/marketing" element={<MarketingPage />} />
        <Route path="/support" element={<SupportPage />} />
      </Routes>
     </section>
    </>
  );
}

export default App;
